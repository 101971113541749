import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Checkbox,
} from "antd";
import Logo from "../../assets/images/logo-black.svg";
import LogoWhite from "../../assets/images/logo-white.svg";
import Lefterrow from "../../assets/images/left-errow.svg";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../../components/Loader";
import encryptDecrypt from "../../helper/encryptDecrypt";
import apiUrl from "../../constants/api_urls";
import { useAppContext } from "../../context/AppContext";
import apiPath from "../../constants/api_urls";
import { ClockCircleOutlined } from "@ant-design/icons";
const { Title } = Typography;
const { Content } = Layout;

const Otp = () => {
  const api = {
    verifyOtp: apiUrl.verifyOtp,
  };
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();

  const { request } = useRequest();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { email, password } = useAppContext();

  const [resendTimer, setResendTimer] = useState(60);
  const [otp, setOtp] = useState("");

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const inputRef7 = useRef(null);

  const onFinish = (values) => {
    console.log("onFinish", values);
    const { email, otp, type } = values;
    let payload = {
      ...values,
      email: email,
      otp: otp,
      type: "",
    };
    onSubmit(payload);
  };

  const handleKeyUp = (event, inputRef) => {
    const input = event.target;
    const inputValue = input.value;

    if (inputValue.length === 1 && !isNaN(inputValue)) {
      otp.length < 7 && setOtp((prevOTP) => prevOTP + inputValue);
      inputRef && inputRef.current && inputRef.current.focus();
    }
  };

  const handleKeyDown = (event, inputRef) => {
    const input = event.target;

    if (event.key === "Backspace" && input.value.length === 0) {
      setOtp((prevOTP) => prevOTP.slice(0, -1));
      inputRef && inputRef.current && inputRef.current.focus();
    } else if (event.key === "Backspace") {
      setOtp((prevOTP) => prevOTP.slice(0, -1));
    }
  };

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [resendTimer]);

  const onNext = () => {
    if (!otp.length === 4) {
      setOtp("");
      return ShowToast("Please enter valid otp", Severty.ERROR);
    }
    const payload = { otp, type: "sign-up" };
    setLoading(true);
    const savedEmail = localStorage.getItem("UGwdfdR2uHMM24N");
    var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
    payload.email = originalEmail;

    request({
      url: apiUrl.verifyOtp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          setOtp("");
          ShowToast(data.message, Severty.SUCCESS);
          setTimeout(() => navigate("/new-password"), 200);
        } else {
          console.log("data", data);
          setOtp("");
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  const handleResend = () => {
    if (resendTimer > 0) return;
    setResendTimer(60); // Reset the timer to 60 seconds
    console.log("email", email);
    const payload = {
      type: "resend",
    };

    const savedEmail = localStorage.getItem("UGwdfdR2uHMM24N");
    var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
    payload.email = originalEmail;

    request({
      url: apiUrl.forgotPassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
    });
  };

  const onSubmit = (values) => {
    console.log("onSubmit", values);
    const { email, otp, type } = values;
    if (!email)
      return ShowToast("Please enter email to sign in", Severty.ERROR);
    const payload = { email, otp, type };
    if (!email) return ShowToast("Please enter valid email ", Severty.ERROR);
    setLoading(true);
    payload.email = email;
    payload.otp = otp;
    payload.type = type;
    request({
      url: api.verifyOtp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          console.log(data);
          setIsLoggedIn(true);
          if (rememberMe) {
            var emailEncrypt = encryptDecrypt.encryptEmail(values.email);
            var passwordEncrypt = encryptDecrypt.encryptPassword(
              values.password,
            );

            localStorage.setItem("rememberMe", "true");
            localStorage.setItem("ykmCe2AYEFTHobn", emailEncrypt);
            localStorage.setItem("ouUsippetc8S4Ry", passwordEncrypt);
          } else {
            localStorage.removeItem("ykmCe2AYEFTHobn");
            localStorage.removeItem("ouUsippetc8S4Ry");
          }
          localStorage.setItem("userProfile", JSON.stringify(data?.data));
          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data.data);
          navigate("/");
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [resendTimer]);

  return (
    <>
      {
        loading && <Loader />
      }
       
      <Layout className="layout-default layout-signin" >
        <Content className="signin">
          <Row className=" align-items-center">
            <Col span={24} sm={6} lg={6} md={6}>
              <div className="signup-logo d-dark-none">
                <img onClick={() => navigate("/")} src={Logo} alt="" />
              </div>
              <div className="signup-logo d-dark-block">
                <img src={LogoWhite} alt="" />
              </div>
            </Col>
            <Col span={24} sm={18} lg={18} md={18}>
              <div className="signup-right">
                <Link className="sign-in-link-type" type="button">
                  Already have a mynt account?
                </Link>
                <Button
                  className="sign-in-btn"
                  type="button"
                  onClick={() => navigate("/login")}
                  // htmlType="submit"
                >
                  Sign in
                </Button>
              </div>
            </Col>
          </Row>
        </Content>
        <div className="otp-outer">
          <span className="back-errow" onClick={() => navigate(-1)}>
            <img src={Lefterrow} />{" "}
          </span>
          <div className="signin-box">
            <Row justify="space-around">
              <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                <div className="signup-form">
                  <Title className="tital-text-sign mb-15">
                    Enter the code we sent to your Email{" "}
                  </Title>
                  <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <div className="otp-form-type">
                      <Form.Item>
                        <Input
                          className="otp-of-type"
                          placeholder=""
                          max={1}
                          value={otp[0]}
                          maxLength={1}
                          ref={inputRef1}
                          onKeyUp={(event) => handleKeyUp(event, inputRef2)}
                          onKeyDown={(event) => handleKeyDown(event, null)}
                        />
                        <Input
                          className="otp-of-type"
                          placeholder=""
                          maxLength={1}
                          value={otp[1]}
                          ref={inputRef2}
                          onKeyUp={(event) => handleKeyUp(event, inputRef3)}
                          onKeyDown={(event) => handleKeyDown(event, inputRef1)}
                        />

                        <Input
                          className="otp-of-type"
                          placeholder=""
                          maxLength={1}
                          value={otp[2]}
                          ref={inputRef3}
                          onKeyUp={(event) => handleKeyUp(event, inputRef4)}
                          onKeyDown={(event) => handleKeyDown(event, inputRef2)}
                        />

                        <Input
                          className="otp-of-type"
                          placeholder=""
                          value={otp[3]}
                          max={1}
                          maxLength={1}
                          ref={inputRef4}
                          onKeyUp={(event) => handleKeyUp(event, inputRef5)}
                          onKeyDown={(event) => handleKeyDown(event, inputRef3)}
                        />

                        {/* <Input className="otp-of-type" placeholder=""
                          value={otp[4]}
                          max={1}
                          maxLength={1}
                          ref={inputRef5}
                          onKeyUp={(event) => handleKeyUp(event, inputRef6)}
                          onKeyDown={(event) => handleKeyDown(event, inputRef4)}
                        />
                        <Input className="otp-of-type" placeholder=""
                          value={otp[5]}
                          max={1}
                          maxLength={1}
                          ref={inputRef6}
                          onKeyUp={(event) => handleKeyUp(event, inputRef7)}
                          onKeyDown={(event) => handleKeyDown(event, inputRef5)}
                        />
                        <Input className="otp-of-type" placeholder="" value={otp[6]}
                          max={1}
                          maxLength={1}
                          ref={inputRef7}
                          onKeyUp={(event) => handleKeyUp(event, null)}
                          onKeyDown={(event) => handleKeyDown(event, inputRef6)} /> */}
                      </Form.Item>
                      <Form.Item className="signin-footer mt-4">
                        <Button
                          className="ant-btn-default"
                          onClick={onNext}
                          disabled={otp.length != 4}
                        >
                          Verify OTP
                        </Button>
                      </Form.Item>
                      <div className="otp-footer">
                        <h6>Didn’t get it?</h6>
                        <span>
                          {resendTimer > 0 ? (
                            <>
                              You can request a new code in{" "}
                              <ClockCircleOutlined /> {resendTimer} seconds.
                            </>
                          ) : (
                            "You can request a new code"
                          )}
                        </span>
                        <Button
                          onClick={handleResend}
                          type="primary"
                          disabled={resendTimer}
                        >
                          {" "}
                          Resend
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>

      </Layout > 
    </>
  );
};

export default Otp;
