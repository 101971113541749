import { Row, Col, Button, Input } from "antd";
import "react-phone-input-2/lib/style.css";
import React, { useState, useContext, useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import useRequest from "../../hooks/useRequest";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Nav,
  Navbar,
  Accordion,
} from "react-bootstrap";

import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import PrivacyImg from "../../assets/images/privacy-img.png";
import lang from "../../helper/langHelper";

function HowWorks() {
  const { request } = useRequest();
  const [data, setData] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getResources = () => {
    setLoading(true);
    request({
      url: `/app/content/get-content/privacy-policy`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        console.log(data, "privacy-policy");
        setLoading(false);
        if (data.data.length) {
          setData(data.data[0]);
        }
      },
      onError: (err) => {
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getResources();
  }, []);

  return (
    <>
      <section className="main">
        <Header />
        <Container>
          <div className="home-outers">
            {/* <Row gutter={24}>
              <Col span={24}>
                <div className="tital transactions-tital ">
                  <h4 className="m-0">How it works</h4>
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <Row gutter={45}>
                    <Col md={9}>
                      <div className="about-img">
                        <img src={PrivacyImg} />
                      </div>
                    </Col>
                    <Col md={15}>
                      <div className="about-mynt">
                        <h6>Mynt is about giving you greater choice and the opportunity to achieve your financial dreams.</h6>
                        <p>My Auto Store recently received the 2023 Certified Automotive Recycler (CAR) and Recall certification from the Automotive Recyclers Association (ARA), who is the voice of the professional automotive recycling industry. The certification allows more than airbag sales, it also provides a guideline of compliance and excellence for businesses to follow a strict set of US standards through the CAR program.</p>
                        <p>My Auto Store is the latest business certified to sell recycled air bags and we are among a small group of businesses certified to sell airbags in the United States this year. As an authorized recycled OEM non-deployed vehicle airbag reseller, customers can legally purchase airbags on our website. Our airbags are a good alternative to ordering a new replacement for a fraction of the price.</p>
                        <p>This is the first time My Auto Store applied for the prestigious CAR certification, and we received an exceptionally high score of 98.57% that is remarkable for a newcomer.</p>
                        <p>As an authorized recycled OEM non-deployed vehicle airbag reseller, customers can legally purchase airbags on our website. Our airbags are a good alternative to ordering a new replacement for a fraction of the price.</p>
                        <p>This is the first time My Auto Store applied for the prestigious CAR certification, and we received an exceptionally high score of 98.57% that is remarkable for a newcomer.</p>
                        <p>As an authorized recycled OEM non-deployed vehicle airbag reseller, customers can legally purchase airbags on our website. Our airbags are a good alternative to ordering a new replacement for a fraction of the pric</p>
                        <p>As an authorized recycled OEM non-deployed vehicle airbag reseller, customers can legally purchase airbags on our website. Our airbags are a good alternative to ordering a new replacement for a fraction of the price.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row> */}
            <Row>
              <Col span={24}>
                <div className="how-to-headding">
                  <p>{lang("GET STARTED IN MINUTES")}</p>
                  <h2>{lang("How It Works?")}</h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} md={24}>
                <div className="work-flow-chart how-sellerskills-works-wrp">
                  <ul className="flow-chart-ul">
                    <li className="work-flow-list">
                      <div className="ss-story-info">
                        <h3>{lang("Open your account")}</h3>
                        <p>
                          {lang(
                            "It’s free and easy. It and only takes a few minutes and you"
                          )}
                        </p>
                      </div>
                      {/* <img src={PrivacyImg} /> */}
                    </li>
                    <li className="work-flow-list work-flow-list--reverse">
                      <div className="ss-story-info">
                        <h3>{lang("Start investing")}</h3>
                        <p>
                          {lang(
                            "You can immediately start buying gold and silver at wholesale prices. No need to pre-fund your account."
                          )}
                        </p>
                      </div>
                      {/* <img src={PrivacyImg} /> */}
                    </li>
                    <li className="work-flow-list">
                      <div className="ss-story-info">
                        <h3>{lang("Transfer funds")}</h3>
                        <p>
                          {lang(
                            "Its quick and easy to transfer money Into your Mynt account using Plaid."
                          )}
                        </p>
                      </div>
                      {/* <img src={PrivacyImg} /> */}
                    </li>
                    <li className="work-flow-list work-flow-list--reverse">
                      <div className="ss-story-info">
                        <h3>{lang("Secure Vault")}</h3>
                        <p>
                          {lang(
                            "Your gold and silver are held in a vault In Zurich, Switzerland with Loomis International."
                          )}
                        </p>
                      </div>
                      {/* <img src={PrivacyImg} /> */}
                    </li>
                    <li className="work-flow-list">
                      <div className="ss-story-info">
                        <h3>{lang("AutoInvest")}</h3>
                        <p>
                          {lang(
                            "Set up daily, weekly or monthly dollar cost averaging so you acquire more metals for your money."
                          )}
                        </p>
                      </div>
                      {/* <img src={PrivacyImg} /> */}
                    </li>
                    <li className="work-flow-list work-flow-list--reverse">
                      <div className="ss-story-info">
                        <h3>{lang("Withdraw Funds")}</h3>
                        <p>
                          {lang(
                            "Seamlessly send funds from your mynt account back to your bank when you need it."
                          )}
                        </p>
                      </div>
                      {/* <img src={PrivacyImg} /> */}
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default HowWorks;
