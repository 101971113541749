import { Card, Col, Row } from "antd";
import axios from "axios";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import { CardBody, Tab, Tabs } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import useRequest from "../../../hooks/useRequest";
import NottfyBell from "../../../assets/images/black_bell.svg";
import componentgold from "../../../assets/images/component-gold.svg";
import Fevicons from "../../../assets/images/mynt-fev.svg";
import GoldChart from "../../../components/chart/GoldChart";
import GoldHistory from "./history";
import GoldRecurring from "./recurring";
import GoldPriceAlert from "./price_alert";
import News from "./news";
import { useAuthContext } from "../../../context/AuthContext";
import LineChart from "../../../components/chart/LineChart";
import ChartGold from "./chart";
import { useAppContext } from "../../../context/AppContext";
import lang from "../../../helper/langHelper";
import { TradingViewGoldWidget } from "./_GoldHeader";

const Gold = ({}) => {
  const { request } = useRequest();
  const [tabValue, setTabValue] = useState();
  const [price, setPrice] = useState(0);

  const { userProfile } = useAuthContext();
  const { goldPrice } = useAppContext();
  const [refresh, setRefresh] = useState();
  const [vault, vaultList] = useState();

  const [gold, setGold] = useState({
    price: 0,
    quantity: 0,
  });

  const getVault = () => {
    request({
      url: `/app/vault/getVaultList`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        console.log(data.data[0], "lllllllllllllllllllllll");
        vaultList(data.data);
      },
    });
  };

  const handleTabChange = (status) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://zylalabs.com/api/2673/live+gold+and+silver+prices+api/2759/latest+rates?base=USD&symbols=XAU",
      headers: {
        Authorization: "Bearer 3622|n0FvI3MmNw3MQmFHxDIQAAdPdMHWLhC7OukSkOtv",
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(
          JSON.stringify(response.data),
          "Price G",
          response.data.rates.USDXAU
        );
        setPrice(response.data.rates.USDXAU);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // handleTabChange()
    getVault();
  }, [refresh]);

  useEffect(() => {
    if (!userProfile) return;
    console.log(userProfile, "metalBalance");

    if (!userProfile.metalBalance) return;

    userProfile.metalBalance.map((item) => {
      if (item.categoryId == "66069035fa639b23dcf4b3a6") {
        const price = item.quantity * item.currentPrice;
        console.log("gold price", price, item.quantity, item.currentPrice);
        setGold({
          price,
          quantity: item.quantity,
        });
      }
    });
  }, [userProfile]);

  return (
    <Row gutter={12} className="mt-3">
      <Col span={24} md={24} className="mb-3">
        <div className="home-car">
          <div className="gold-card-head">
            <div className="trading-hart-mpney trading-hart-mpney-main88">
              {userProfile?.length > 0 && (
                <div className="main-price-data-show">
                  <div>
                    <h4>{gold?.quantity?.toFixed(3)} OZ</h4>
                  </div>
                  <div className="left-0">
                    <p className="currncy-v-text ml-0">{lang("Value in")} $</p>
                    <h4>
                      {parseFloat(gold?.price?.toFixed(2)).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}
                    </h4>
                  </div>
                </div>
              )}
              <TradingViewGoldWidget />
            </div>
          </div>

          <div className="position-relative">
            {/* <GoldChart /> */}
            {/* <ChartGold /> */}
            <div className="gold-fiv-money">
              {/* <h4>
                <span>Current Gold Price</span> : ${goldPrice?.toFixed(2)}
              </h4> */}
            </div>
          </div>
        </div>
      </Col>

      {/* {vault && (
                vault
                    .filter(data => data.categoryId?.categories === "Gold") // Filter out items where categories is not "Gold"
                    .map((data, index) => (
                        <Col key={index} span={24} md={24} className="mb-30">
                            <Col span={24} className="p-0">
                                <div className="table-headding">
                                    Overview
                                </div>
                            </Col>
                            <div className="Overview-list">
                                <ul>
                                    <li className="overview-list-items">
                                        <span>Vault</span>
                                        <h6>{data?.vaultName}</h6>
                                    </li>
                                    <li className="overview-list-items">
                                        <span>Location</span>
                                        <h6>{data?.country}</h6>
                                    </li>
                                    <li className="overview-list-items">
                                        <span>Premium</span>
                                        <h6>{data.premium}</h6>
                                    </li>
                                    <li className="overview-list-items">
                                        <span>Buy Price Per Oz</span>
                                        <h6>{data?.buyPricePerOz}</h6>
                                    </li>
                                    <li className="overview-list-items">
                                        <span>Verified by</span>
                                        <h6>{data?.verifiedBy}</h6>
                                    </li>
                                    <li className="overview-list-items">
                                        <span>Insurance</span>
                                        <h6>{data?.insurance}</h6>
                                    </li>
                                    <li className="overview-list-items">
                                        <span>Minimum Storage Fee</span>
                                        <h6>{data?.minimumStorageFee}</h6>
                                    </li>
                                    <li className="overview-list-items">
                                        <span>Sell Price Per Oz</span>
                                        <h6>{data?.sellPricePerOz}</h6>
                                    </li>
                                    <li className="overview-list-items">
                                        <span>Spread</span>
                                        <h6>{data?.spread}</h6>
                                    </li>
                                    <li className="overview-list-items" style={{ maxWidth: "fit-content" }}>
                                        <span>Storage</span>
                                        <h6>{data?.storage}</h6>
                                    </li>
                                    <li className="overview-list-items" style={{ maxWidth: "fit-content" }}>
                                        <span>Allocation</span>
                                        <h6>{data?.allocation}</h6>
                                    </li>
                                </ul>
                                <p className="last-formate">U.S. Silver is a vaulted position of investment-grade physical gold, that is allocated, insured, audited, and stored with one of our premier vaulting partners. U.S. Gold is stored with APMEX, Brinks, Loomis international, or other COMEX approved vaults.</p>
                            </div>
                        </Col>
                    ))
            )} */}

      {userProfile && (
        <Col span={24} md={24} className="mb-3">
          <div className="profile-tital">
            {" "}
            <h4>{lang("Activity")}</h4>
          </div>
          <div className="buy-now-tab">
            <GoldHistory />
            {/* <Tabs className="buy-now-list"   >
                        <Tab title="History" eventKey="timesheet" className="transavtion">
                            <GoldHistory />
                        </Tab>
                        <Tab title="Recurring buys" eventKey="leaveRequest">
                            <GoldRecurring />
                        </Tab>
                        <Tab title="Price alerts" eventKey="product" className="transavtion">
                            <GoldPriceAlert />
                        </Tab>
                        <Tab title="News" eventKey="news" className="transavtion">
                            <News />
                        </Tab>
                    </Tabs> */}
            <div></div>
          </div>
        </Col>
      )}
    </Row>
  );
};

const SilveryChartNew = () => {
  const [chart, setChart] = useState();

  const { request } = useRequest();

  const getVault = () => {
    request({
      url: `/app/chart/silver?type=1W&`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        setChart(data);
      },
    });
  };

  useEffect(() => {
    getVault();
  }, []);

  return (
    <>
      <LineChart />
    </>
  );
};
export default Gold;
