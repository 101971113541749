import {
  Button,
  Collapse,
  Form,
  Input,
  InputNumber,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { useNavigate } from "react-router-dom";
import Righterr from "../../assets/images/right-errow.png";
import CurrencyImg from "../../assets/images/usd.svg";
import { useAppContext } from "../../context/AppContext";
import { useAuthContext } from "../../context/AuthContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import Gold from "../../assets/images/gold_circle_.svg";
import Silver from "../../assets/images/silver_circle_.svg";
import { TimerCount } from "./_Buy";
import lang from "../../helper/langHelper";
const { Panel } = Collapse;

const Sell = ({}) => {
  const [loading, setLoading] = useState(false);

  const {
    prices,
    goldRates,
    silverRates,
    silverPrice,
    goldPrice,
    serviceFee,
    setRefreshBalance,
  } = useAppContext();
  const { goldAccount, silverAccount } = useAuthContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [amount, setAmount] = useState(300);
  const [networkFee, setNetworkFee] = useState(0);
  const [processingFee, setProcessingFee] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [categoryId, setCategoryId] = useState();
  const [category, setCategory] = useState();
  const [isGold, setIsGold] = useState(false);
  const { language } = useAppContext();

  useEffect(() => {
    if (!prices.length) return;
    setCategory(prices[0]);
    setCategoryId(prices[0]._id);
  }, [prices]);

  const onFinish = (values) => {
    const payload = {
      typeOfTransaction: "Sell",
      categoryId,
      payment_method: "cashBalance",
      quantity,
    };
    setLoading(true);
    request({
      url: `/app/transaction/createOrder`,
      method: "POST",
      data: payload,
      onSuccess: (res) => {
        const { data } = res;
        if (res.statusText == "Success") {
          const { quantity, payment_method, amount, _id: orderId } = data;
          //  transaction({ orderId, quantity, amount, payment_method })
          // setLoading(false)

          navigate(`/${orderId}/Sell/order/complete`);
        } else {
          setLoading(false);
        }
        console.log(data, "RESouce");
      },
      onError: (err) => {
        setLoading(false);
        if (err?.response?.data) {
          ShowToast(err?.response?.data?.message, Severty.ERROR);
        }
        // form.setFieldsValue({ categoryId: '', frequency: '', amount: '' })
      },
    });
  };

  useEffect(() => {
    if (!category) return;
    category.atomicSymbol == "XAU" ? setIsGold(true) : setIsGold(false);
  }, [category]);

  const transaction = ({ orderId, quantity, amount, payment_method }) => {
    const payload = {
      orderId,
      quantity,
      amount,
      payment_method,
    };
    setLoading(true);
    request({
      url: `/app/transaction/transaction`,
      method: "POST",
      data: payload,
      onSuccess: (res) => {
        form.setFieldsValue({
          categoryId: "",
          frequency: "",
          amount: "",
          quantity: "",
        });
        const { data } = res;
        console.log(data, "RESouce");
        setLoading(false);
        ShowToast(lang("Sell successfully"), Severty.SUCCESS);
        // setData(data.data);
        setRefreshBalance((prev) => !prev);
        setQuantity();
        // setAmount()
      },
      onError: (err) => {
        setLoading(false);
        if (err?.response?.data) {
          ShowToast(err?.response?.data?.message, Severty.ERROR);
        }
      },
    });
  };

  const [showCurrencySelector, setShowCurrencySelector] = useState(false);
  const [metalSelector, showMetalSelector] = useState(false);

  useEffect(() => {
    if (!goldRates || !category || !serviceFee) return;

    if (category.atomicSymbol == "XAU") {
      const sellAmount = (quantity * goldRates).toFixed(2);
      // const processing =  (serviceFee.percentageOnTransaction / 100) * sellAmount;
      const amount = Number(sellAmount); //- processing;
      setAmount(parseFloat(amount.toFixed(2)));
      // setProcessingFee(processing);
    } else if (category.atomicSymbol == "XAG") {
      const sellAmount = (quantity * silverRates).toFixed(2);

      // const processing =  (serviceFee.percentageOnTransaction / 100) * sellAmount;
      const amount = Number(sellAmount); //- processing;
      setAmount(parseFloat(amount.toFixed(2)));
      // setProcessingFee(processing);
      // const quantity = purchaseAmount * silverRates.XAG;
      //  setQuantity(parseFloat(quantity.toFixed(4)))
    }
  }, [quantity, categoryId, serviceFee, goldRates, silverRates]);

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="row-col mt-3"
      >
        {metalSelector && (
          <MetalDropDown
            value={categoryId}
            show={metalSelector}
            hide={() => showMetalSelector(false)}
            onSelect={(data) => {
              setCategory(data);
              setCategoryId(data._id);
            }}
          />
        )}
        {/* <div className="buying-header">
                    <h5>You are Selling</h5>
                    <h6>Switzerland {category && category.categories}</h6>
                    <p>Spot at {" "}
                        {isGold ? `$${goldPrice.toFixed(2)}` : `$${silverPrice.toFixed(2)}`}
                    </p>
                </div> */}
        <Form.Item
          className="username-form"
          label=""
          name="amount"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!quantity || quantity == 0) {
                  return Promise.reject(
                    new Error(lang("Please enter quantity"))
                  );
                }
                return Promise.resolve();
              },
            }),
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (categoryId == "66069035fa639b23dcf4b3a6") {
                  if (quantity > goldAccount.quantity) {
                    return Promise.reject(
                      new Error(
                        lang(
                          "You do not have the amount specified in your account to sell"
                        )
                      )
                    );
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  if (quantity > silverAccount.quantity) {
                    return Promise.reject(
                      new Error(
                        lang(
                          "You do not have the amount specified in your account to sell"
                        )
                      )
                    );
                  } else {
                    return Promise.resolve();
                  }
                }
              },
            }),
          ]}
        >
          <div className="currency-selec-group">
            <InputNumber
              value={quantity}
              min={0}
              placeholder="Enter Sell Quantity"
              className="ant-input ant-input-status-success  buy-sale"
              // type="text"
              onChange={(value) => {
                setQuantity(value);
              }}
            />
            <div className="currency-main d-flex  align-items-center ps-3  ml-auto">
              <span className="currency-teg">OZ</span>

              <Select
                className="buy-ctg"
                defaultValue={lang("Gold")}
                onChange={(id) => {
                  const find = prices.find((item) => item._id == id);
                  if (find) {
                    setCategory(find);
                    setCategoryId(find._id);
                  }
                }}
              >
                {prices.map((item) => (
                  <Select.Option
                    value={item._id}
                    label={
                      language == "en" ? item.categories : item.ar_categories
                    }
                  >
                    {language == "en" ? item.categories : item.ar_categories}
                  </Select.Option>
                ))}

                {/* <Select.Option  value="silver">Silver</Select.Option> */}
              </Select>
              {/* <Button className="CurrencySelector__SelectorButton" type="button" onClick={() => showMetalSelector(true)}>
                                <span className="CurrencySelector_img">
                                
                                </span>
                                <p className="text-callout">{category && category.categories} </p>
                                <div className="w-5 h-5">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5 ml-1 text-label-tertiary">
                                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>

                            </Button> */}
            </div>
          </div>
        </Form.Item>

        <Form.Item className="username-form" label="" name="">
          <div className="currency-selec-group">
            <InputNumber
              value={amount.toFixed(2)}
              min={0}
              placeholder={lang("Enter Sell Quantity")}
              className="ant-input ant-input-status-success  buy-sale"
              // type="text"
              onChange={(value) => {
                setQuantity(value);
              }}
            />
            <div className="currency-main d-flex  align-items-center ps-3  ml-auto">
              <span className="currency-teg">$</span>
            </div>
          </div>
        </Form.Item>
        <div className="mb-3">
          {quantity > 0 && (
            <Collapse
              accordion
              className="collapse-main"
              //  activeKey={`2`}
            >
              {[{ key: "2" }].map((item) => (
                <Panel
                  key={item.key}
                  header={
                    <div className="collaps-custom-header">
                      {lang("You get")}{" "}
                      <span className="bold-font">~ $ {amount.toFixed(2)}</span>{" "}
                      {lang("for")}{" "}
                      <span className="bold-font">
                        {quantity} OZ{" "}
                        {category && language == "en"
                          ? category?.categories
                          : category?.ar_categories}
                      </span>
                    </div>
                  }
                >
                  <div className="collaps-body">
                    <div className="update-text">
                      <span>
                        {lang("Price")}
                        {/* <Tooltip title="prompt text" className="tooltip-bar">
                                                <span><InfoCircleOutlined /></span>
                                            </Tooltip> */}
                      </span>
                      <p>$ {amount.toFixed(2)}</p>
                    </div>
                    {/* <div className="update-text">
                    <span>
                      Processing fee
                      <Tooltip
                        title="This is charged by Mynt to cover costs associated with payment processing. This may vary based on the payment method you choose later"
                        className="tooltip-bar"
                      >
                        <span>
                          <InfoCircleOutlined />
                        </span>
                      </Tooltip>
                    </span>
                    <p>USD {processingFee.toFixed(2)}</p>
                  </div> */}
                  </div>
                </Panel>
              ))}
            </Collapse>
          )}
        </div>
        <TimerCount count={10} onComplete={() => null} type={"Sell"} />
        {/* <div className="chose-visa-type">
                    <div>
                        <img src={Visa} />
                    </div>
                    <div className="card-detail">
                        <span>Visa Debit - 8066</span>
                        <img src={Righterr} />
                    </div>
                </div> */}

        <div className=" ">
          <Form.Item>
            <Button
              loading={loading}
              className="visa-in-btn"
              type="button"
              htmlType="submit"
            >
              {lang("Sell")}{" "}
              {category && language == "en"
                ? category?.categories
                : category?.ar_categories}{" "}
              <img src={Righterr} />
            </Button>
          </Form.Item>
          {/* <Button className="re-visa-in-btn" type="button">
                        Review order
                    </Button> */}
        </div>
      </Form>
    </>
  );
};

const MetalDropDown = ({ hide, onSelect, value }) => {
  const { prices } = useAppContext();
  const [metals, setMetals] = useState([...prices]);

  const onSearch = ({ target }) => {
    console.log(target.value, "text");
    const filter = prices.filter((cur) =>
      cur.categories
        .toLocaleLowerCase()
        .includes(target.value.toLocaleLowerCase())
    );

    setMetals(filter);
  };

  return (
    <div className="currency-inset ">
      <div className="inset-header">
        <h1 className="">{lang("Select a metal")}</h1>
        <button className="headerclosebutton" type="button" onClick={hide}>
          <svg
            fill="none"
            height="24"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
      <div className="mt-4">
        <div className="searchbar-menu">
          <div className="serch-iconbar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5 text-label-tertiary"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              ></path>
            </svg>
          </div>
          <Input
            className="currency-serch"
            placeholder={lang("Search")}
            onChange={onSearch}
          />
        </div>
      </div>
      <div className="currency-select-body">
        <ul>
          {metals.map((item) => (
            <li key={item}>
              <Button
                className="currency-btn-body"
                type="button"
                onClick={() => {
                  onSelect(item);
                  hide();
                }}
              >
                <span className="currency-flag">
                  <img
                    className=""
                    width={25}
                    src={item.atomicSymbol == "XAU" ? Gold : Silver}
                    alt="aud"
                  />
                </span>
                <div className="currency-names">
                  <span className="">
                    <h6 className="">{item.categories}</h6>
                    <p className="">{item.atomicSymbol}</p>
                  </span>
                </div>
                <span className="right-btn-icon ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-testid="chevron-right-icon"
                    width="20"
                    height="20"
                    color="var(--label-tertiary)"
                    className="text-label-tertiary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sell;
