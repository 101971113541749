import { Button, Empty, Pagination } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import useRequest from "../../../hooks/useRequest";
import Dotted from "../../../assets/images/dotted.svg";
import RightIcon from "../../../assets/images/right-errow.png";
import ViewTransactionModal from "../../Portfolio/ViewModal";
import { dateString } from "../../../helper/functions";
import TransactionModal from "../../../components/TransactionModal";
import { useNavigate } from "react-router";
import lang from "../../../helper/langHelper";
import { useAppContext } from "../../../context/AppContext";
import Loader from "../../../components/Loader";

const GoldHistory = () => {
  const { request } = useRequest();
  const navigate = useNavigate();
  const { language } = useAppContext();
  const [loading ,setLoading] = useState(false)

  const [history, setHistory] = useState([]);
  const [selected, setSelected] = useState();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const showModal = (data) => {
    navigate(`/transaction/detail`, { state: data });
  };

  const handleChangePage = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
      pageSize: pageSize,
    }));
    getHistory(page, pageSize);
  };

  const getHistory = (

    page = pagination.current,
    pageSize = pagination.pageSize
  ) => {
    setLoading(true)

    request({
      url: `/app/transaction/getByCategoryId/66069035fa639b23dcf4b3a6?page=${page}&limit=${pageSize}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setLoading(false)
        console.log(data.getData,"getData----------->")
        setHistory(data.getData);
        setPagination({
          current: data.page,
          pageSize: data.pageSize,
          total: data.total,
        });
      },
    });
  };

  useEffect(() => {
    setLoading(true)
    getHistory();
  }, []);

  console.log(history,"history---------->")

  return (
    <>
      {loading ? <Loader/> :
      history.length ? (
   
       history.map((data, index) => (
          <div
            key={index}
            className="Transactions-header"
            onClick={() => showModal(data)}
          >
            <div className="transactions-list">
              <h6>
                {language === "en"
                  ? data?.typeOfTransaction
                  : data?.typeOfTransaction}{" "}
                {language === "en"
                  ? data?.categoryId?.categories
                  : data?.categoryId?.ar_categories}
              </h6>
              <span>{data?.quantity?.toFixed(3)} oz</span>
            </div>

            <div className="date-text">
              <div className="errow-r">
                <img width={7} src={RightIcon} alt="Right Icon" />
              </div>
              <p>{dateString(data.dateOfTransaction, "ll")}</p>
            </div>
          </div>
        ))
      ) : (
        <Empty />
      )}

      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={handleChangePage}
      />
    </>
  );
};

export default GoldHistory;
