import {
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  Select,
} from "antd";


import OwlCarousel from 'react-owl-carousel3';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery'
import 'react-phone-input-2/lib/style.css'
import React, { useState, } from "react";
import useRequest from "../../hooks/useRequest";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import { VerticalAlignBottomOutlined, VerticalAlignTopOutlined, SwapOutlined } from "@ant-design/icons"
import { CardBody, CardFooter, CardTitle, Container, Dropdown, Nav, Navbar, ModalTitle } from "react-bootstrap";
import Logo from '../../assets/images/logo-white.svg'
import Usd from '../../assets/images/currency-usd.png'
import Exchange from '../../assets/images/exchange.png'
import User from '../../assets/images/user-bold.png'
import Nottfy from '../../assets/images/bell-outline.png'
import Begin01 from '../../assets/images/begin01.jpg'
import Begin02 from '../../assets/images/begin01.jpg'
import Begin03 from '../../assets/images/begin01.jpg'
import Visa from '../../assets/images/visa.png'
import Righterr from '../../assets/images/right-errow.png'
import payments01 from '../../assets/images/Possiblepayments01.svg'
import payments02 from '../../assets/images/Possible-payments.svg'
import payments03 from '../../assets/images/Possible-payments 02.svg'
import payments04 from '../../assets/images/Possible-payments 03.svg'
import Playstore from '../../assets/images/play-app-store.svg'
import iphone from '../../assets/images/iphone-app-store.svg'
import Toperrow from '../../assets/images/top-errow.svg'
import Dawnerrow from '../../assets/images/red-dawn.svg'
import GoldPrime from '../../assets/images/gold-prime.png'
import Myntcard from '../../assets/images/myntcard.jpg'

import Cash from '../../assets/images/icon/cash.svg'
import Curncy from '../../assets/images/icon/curncy.svg'
import Bank from '../../assets/images/icon/bank.svg'
import Mynt from '../../assets/images/icon/mynt.svg'
import Credit from '../../assets/images/icon/credit.svg'
import Paypal from '../../assets/images/icon/paypal.svg'
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";

function HomeNew() {
  const { request } = useRequest()
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const [visible, setVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [viewData, setViewData] = useState();
  const [visibleView, setVisibleView] = useState(false);
  const navigate = useNavigate()

  const owlCarouselOptions = {
    items: 3,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1.1,
      },
      600: {
        items: 2.1,
      },
      1000: {
        items: 3,
      },
    },
  };

  const owlCarouselOptions02 = {
    items: 3,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1.1,
      },
      600: {
        items: 1.1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <section className="main">
        <Header />
        <Container>
          <div className="account-header">
            <ul>
              <li className="account-nav">
                <p>Account Value:</p>
                <h6>$6,903.64</h6>
              </li>
              <li className="account-nav">
                <p>Total Performance:</p>
                <span>$0.02 <img src={Dawnerrow} /></span>
              </li>
              <li className="account-nav">
                <p>Cash Balance:</p>
                <h6>$6,903.64</h6>
              </li>
              <li className="account-nav">
                <p>Metal Holding:</p>
                <h6>$6,903.64</h6>
              </li>
            </ul>
          </div>
          <div className="home-outers">
            <Row gutter={24}>
              <Col span={24} lg={16} className="order-2 order-lg-1">

                <Row gutter={12}>
                  <Col span={24} md={24} lg={12} className="mb-5">
                    <div className="home-card-p">
                      <Card >
                        <CardBody className="home-card-body">
                          <div className="first-boxbar">
                            <h6>Your account balance is</h6>
                            <span className="bg-text">up $0.41 (2.52%)</span>
                            <div className="balancebar-price">
                              <span>Total balance</span>
                              <h2>$16.76</h2>
                            </div>
                          </div>
                        </CardBody>
                        <Button className="ant-btn-default white-btn" type="button" >
                          View portfolio
                        </Button>
                      </Card>
                    </div>
                  </Col>
                  <Col sm={24} md={24} lg={12} className="mb-5">
                    <div className="home-card-p">
                      <Card >
                        <CardBody className="home-card-body">
                          <h4>Set Up Auto Invest</h4>
                          <p>Build your portfolio with automatic investments.</p>
                        </CardBody>
                        <Button className="ant-btn-default white-btn" type="button" >
                          Start Now
                        </Button>
                      </Card>
                    </div>
                  </Col>
                </Row>

                <Row gutter={12}>
                  <Col span={24}>
                    <div className="tital transactions-tital ">
                      <h4 className="m-0">Recent Transactions</h4>
                      <Link>View all
                      </Link>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Card className="transaction-section">
                      <div className="transaction-history">
                        <ul>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Bought Gold</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span>+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Sold Silver</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Platinum</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Bought Gold</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Silver</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Platinum</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Bought Gold</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Bought Gold</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Bought Gold</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row gutter={12} className="mt-5">
                  <Col span={24}>
                    <div className="tital">
                      <h4>Resources for you</h4>
                    </div>
                  </Col>
                  <OwlCarousel  {...owlCarouselOptions}
                    className="owl-theme owl-resources"
                  >
                    <Col span={24}>
                      <div className="resources-card-p">
                        <Card>
                          <CardBody className="resources-card-body">
                            <img src={Begin01}></img>
                            <h5>Begin with Metals</h5>
                            <p>Create a strong foundation by understanding the most well-known metals on the market.</p>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="resources-card-p">
                        <Card>
                          <CardBody className="resources-card-body">
                            <img src={Begin02}></img>
                            <h5>Begin with Metals</h5>
                            <p>Create a strong foundation by understanding the most well-known metals on the market.</p>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="resources-card-p">
                        <Card>
                          <CardBody className="resources-card-body">
                            <img src={Begin03}></img>
                            <h5>Begin with Metals</h5>
                            <p>Create a strong foundation by understanding the most well-known metals on the market.</p>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                  </OwlCarousel>

                </Row>
                <Row gutter={12} className="mt-5">
                  <Col span={24}>
                    <div className="tital">
                      <h4>Recent News</h4>
                    </div>
                  </Col>
                  <OwlCarousel {...owlCarouselOptions02}
                    className="owl-theme owl-resources"
                  >
                    <Col span={24}>
                      <div className="resources-card-p">
                        <Card>
                          <CardBody className="resent-gold-card-body">
                            <div className="resent-gold">
                              <div className="resent-img-gold">
                                <img src={GoldPrime}></img>
                              </div>
                              <div className="resent-text-gold">
                                <h5>Gold, silver prices unchanged; yellow metal trading at Rs 63,050/10g</h5>
                                <p>The price of 24-carat gold remained unchanged in early trade on Monday, with ten grams of the precious metal selling at Rs 63,050, according to the GoodReturns website. The price of silver was also unchanged, with one kilogram of the precious metal selling at Rs 75,500.</p>
                              </div>
                            </div>
                            <Button className="resent-btn mt-3">Read more</Button>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="resources-card-p">
                        <Card>
                          <CardBody className="resent-gold-card-body">
                            <div className="resent-gold">
                              <div className="resent-img-gold">
                                <img src={GoldPrime}></img>
                              </div>
                              <div className="resent-text-gold">
                                <h5>Gold, silver prices unchanged; yellow metal trading at Rs 63,050/10g</h5>
                                <p>The price of 24-carat gold remained unchanged in early trade on Monday, with ten grams of the precious metal selling at Rs 63,050, according to the GoodReturns website. The price of silver was also unchanged, with one kilogram of the precious metal selling at Rs 75,500.</p>
                              </div>
                            </div>
                            <Button className="resent-btn mt-3">Read more</Button>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                  </OwlCarousel>

                </Row>
              </Col>
              <Col sm={24} lg={8} className="order-1 order-lg-2">
                <div className="invest-card">
                  <Card>
                    <div className="investment-nav-bar">
                      <Link className="investment-btn active" type="button" >Buy</Link>
                      <Link className="investment-btn " type="button" >Sell</Link>
                      <Link className="investment-btn" type="button" >Swap</Link>
                    </div>
                    <Form layout="vertical" className="row-col mt-3">
                      <Form.Item className="username-form" name="" >
                        <Select className="form-of-type first-type-in" placeholder="">
                          <Select.Option className="">
                            Gold
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item className="username-form sec-type-in" label="Frequency" name="" >
                        <Select className="form-of-type" placeholder="Once">
                          <Select.Option>
                            Once
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item className="username-form" label="Amount" name="" >
                        <Input className="form-of-type" type="text" value="$10" placeholder="10$" />
                      </Form.Item>
                      <div className="list-items-btn">
                        <span>$50</span>
                        <span>$100</span>
                        <span>$500</span>
                        <span>$1000</span>
                      </div>
                      <div className="visa-inner-drop">
                        <Link className="chose-visa-type" >
                          <div>
                            <img src={Visa} />
                          </div>
                          <div className="card-detail">
                            <span>Visa Debit - 8066</span>
                            <img src={Righterr} />
                          </div>
                        </Link>
                        <div className="visa-dropdawn d-none">
                          <ul>
                            <li>
                              <img src={Cash} />
                              <div className="dropdawn-visa-text">
                                <h6>Cash Balance: <span>$0.00</span></h6>
                                <p>$0.01 minimum</p>
                              </div>
                            </li>
                            <li>
                              <img src={Curncy} />
                              <div className="dropdawn-visa-text">
                                <h6>ACH/eCheck</h6>
                                <p>$0.01 minimum</p>
                              </div>
                            </li>
                            <li>
                              <img src={Bank} />
                              <div className="dropdawn-visa-text">
                                <h6>Bank Wire</h6>
                                <p>$0.01 minimum</p>
                              </div>
                            </li>
                            <li>
                              <img src={Mynt} />
                              <div className="dropdawn-visa-text">
                                <h6>Mynt</h6>
                                <p>$0.01 minimum</p>
                              </div>
                            </li>
                            <li>
                              <img src={Credit} />
                              <div className="dropdawn-visa-text">
                                <h6>Credit/Debit Card</h6>
                                <p>$0.01 minimum</p>
                              </div>
                            </li>
                            <li>
                              <img src={Paypal} />
                              <div className="dropdawn-visa-text">
                                <h6>PayPal</h6>
                                <p>$0.01 minimum</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="d-flex ">
                        <Button className="visa-in-btn" type="button" >
                          Instant  <img src={Righterr} />
                        </Button>
                        <Button className="re-visa-in-btn" type="button">
                          Review order
                        </Button>
                      </div>
                    </Form>
                  </Card>
                </div>
                <Card className="invite-frnd mt-3">
                  <CardBody className="home-card-body">
                    <h4>Earn $50k in gold when you refer friends to Mynt.</h4>
                    {/* <p>Get $10 in metal when you invite a friend and they trade $100 USD.</p> */}
                  </CardBody>
                  <CardFooter>
                    <div className="share-link-now">
                      <span>https://www.mynt.com/share</span>
                      <Button className="ant-btn-default" type="button" >Copy</Button>
                    </div>
                  </CardFooter>
                </Card>
                <Card className="credit-card-box">
                  <CardTitle>The mynt Credit Card&trade;</CardTitle>
                  <CardBody>
                    <img className="card-imagas" src={Myntcard} />
                    <p className="card-line-text">mynt Foundation Derivatives is currently not available in your region. Sign up to get notified when Derivatives become available in US.</p>
                  </CardBody>
                  <CardFooter>
                    <Button className="ant-btn-default mt-3" type="button">See if you pre-qualify</Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}



export default HomeNew;
