import {
  Button,
  Collapse,
  Form,
  Input,
  InputNumber,
  Select,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { useNavigate } from "react-router-dom";
import Righterr from "../../assets/images/right-errow.png";
import CurrencyImg from "../../assets/images/usd.svg";
import Visa from "../../assets/images/visa.png";
import { useAppContext } from "../../context/AppContext";
import { useAuthContext } from "../../context/AuthContext";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import Gold from "../../assets/images/gold_circle_.svg";
import Silver from "../../assets/images/silver_circle_.svg";
import { TimerCount } from "./_Buy";
import lang from "../../helper/langHelper";

const { Panel } = Collapse;

const Swap = ({}) => {
  const [loading, setLoading] = useState(false);

  const {
    prices,
    goldRates,
    silverRates,
    goldPrice,
    silverPrice,
    serviceFee,
    setRefreshBalance,
  } = useAppContext();
  const { isLoggedIn, goldAccount, silverAccount } = useAuthContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [amount, setAmount] = useState(300);
  const [networkFee, setNetworkFee] = useState(0);
  const [processingFee, setProcessingFee] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [categoryId, setCategoryId] = useState();
  const [swapId, setSwapId] = useState();
  const [category, setCategory] = useState();
  const [swap, setSwap] = useState();
  const [swapQuantity, setSwapQuantity] = useState();
  const [isGold, setIsGold] = useState(false);
  const { language } = useAppContext();

  useEffect(() => {
    if (!prices.length) return;
    setCategory(prices[0]);
    setCategoryId(prices[0]._id);

    setSwap(prices[1]);
    setSwapId(prices[1]._id);
  }, [prices]);

  useEffect(() => {
    if (!category) return;
    category.atomicSymbol == "XAU" ? setIsGold(true) : setIsGold(false);
  }, [category]);

  const onFinish = (values) => {
    const { amount } = values;
    const payload = {
      typeOfTransaction: "Swap",
      categoryId,
      amount,
      payment_method: "cashBalance",
      swapId,
      quantity,
    };
    setLoading(true);
    request({
      url: `/app/transaction/createOrder`,
      method: "POST",
      data: payload,
      onSuccess: (res) => {
        const { data } = res;
        if (res.statusText == "Success") {
          const { quantity, payment_method, amount, _id: orderId } = data;

          // transaction({ orderId, quantity, amount, payment_method })

          navigate(`/${orderId}/Swap/order/complete`);
        } else {
          setLoading(false);
        }
        console.log(data, "RESouce");
      },
      onError: (err) => {
        setLoading(false);
        if (err?.response?.data) {
          ShowToast(err?.response?.data?.message, Severty.ERROR);
        }
      },
    });
  };

  const transaction = ({ orderId, quantity, amount, payment_method }) => {
    const payload = {
      orderId,
      quantity,
      amount,
      payment_method,
    };
    setLoading(true);
    request({
      url: `/app/transaction/transaction`,
      method: "POST",
      data: payload,
      onSuccess: (res) => {
        form.setFieldsValue({
          categoryId: "",
          frequency: "",
          amount: "",
          swapId: "",
        });
        const { data } = res;
        console.log(data, "RESouce");
        setLoading(false);
        ShowToast(lang("Swap successfully"), Severty.SUCCESS);
        setRefreshBalance((prev) => !prev);
        setQuantity();
        // setData(data.data);
      },
      onError: (err) => {
        form.setFieldsValue({
          categoryId: "",
          frequency: "",
          amount: "",
          swapId: "",
        });
        console.log(err, "transaction");
        setLoading(false);
        if (err?.response?.data) {
          ShowToast(err?.response?.data?.message, Severty.ERROR);
        }
      },
    });
  };

  const [swapSelector, setSwapSelector] = useState(false);
  const [metalSelector, showMetalSelector] = useState(false);

  useEffect(() => {
    if (!goldRates || !category || !serviceFee || !silverRates) return;

    if (category.atomicSymbol == "XAU") {
      const sellAmount = (quantity * goldRates).toFixed(2);
      // const processing = (serviceFee.paymentGatewayCharges / 100) * sellAmount
      const amount = Number(sellAmount); //- processing
      setAmount(parseFloat(amount.toFixed(2)));
      // setProcessingFee(processing)

      const swapQty = Number((amount / silverRates)).toFixed(2);
      setSwapQuantity(parseFloat(swapQty));
    } else if (category.atomicSymbol == "XAG") {
      const sellAmount = Number(quantity * silverRates).toFixed(2);
      // const processing = (serviceFee.paymentGatewayCharges / 100) * sellAmount
      const amount = Number(sellAmount); //- processing
      setAmount(parseFloat(amount.toFixed(2)));
      // setProcessingFee(processing)

      const swapQty = Number((amount / goldRates)).toFixed(2);
      setSwapQuantity(parseFloat(swapQty));

      // const quantity = purchaseAmount * silverRates.XAG;
      //  setQuantity(parseFloat(quantity.toFixed(4)))
    }
  }, [quantity, categoryId, serviceFee, goldRates, silverRates, swapId]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="row-col mt-3"
    >
      {metalSelector && (
        <MetalDropDown
          value={categoryId}
          show={metalSelector}
          id={categoryId}
          hide={() => showMetalSelector(false)}
          onSelect={(data) => {
            setCategory(data);
            setCategoryId(data._id);
            const swap = prices.find((item) => item._id != data._id);
            if (swap) {
              setSwap(swap);
              setSwapId(swap._id);
            }
          }}
        />
      )}

      {swapSelector && (
        <MetalDropDown
          value={swapId}
          id={swapId}
          show={swapSelector}
          hide={() => setSwapSelector(false)}
          onSelect={(data) => {
            setSwap(data);
            setSwapId(data._id);

            const category = prices.find((item) => item._id != data._id);
            if (category) {
              setCategory(category);
              setCategoryId(category._id);
            }
          }}
        />
      )}

      {/* <div className="buying-header">
                <h5>You are Swaping</h5>
                <h6>Switzerland {category && category.categories}</h6>
                <p>Spot at {" "}
                    {isGold ? `$${goldPrice.toFixed(2)}` : `$${silverPrice.toFixed(2)}`}
                </p>
            </div> */}

      <Form.Item
        className="username-form"
        label=""
        name="categoryId"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!quantity) {
                return Promise.reject(
                  new Error(lang("Please enter valid quantity"))
                );
              }
              return Promise.resolve();
            },
          }),
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (categoryId == "66069035fa639b23dcf4b3a6") {
                if (quantity > goldAccount.quantity) {
                  return Promise.reject(
                    new Error(
                      lang(
                        "You do not have the amount specified in your account to sell"
                      )
                    )
                  );
                } else {
                  return Promise.resolve();
                }
              } else {
                if (quantity > silverAccount.quantity) {
                  return Promise.reject(
                    new Error(
                      lang(
                        "You do not have the amount specified in your account to sell"
                      )
                    )
                  );
                } else {
                  return Promise.resolve();
                }
              }
            },
          }),
        ]}
      >
        <div className="currency-selec-group currency-changes">
          <div>
            <InputNumber
              value={quantity}
              min={0}
              placeholder={lang("Enter swap quantity")}
              className="ant-input ant-input-status-success  buy-sale"
              // type="text"
              onChange={(value) => {
                setQuantity(value);
              }}
            />
            {isNaN(amount) ? null : <p className="sale-value-p">$ {amount}</p>}
          </div>
          <div className="currency-main d-flex  align-items-center ps-3  ml-auto">
            <span className="currency-teg">OZ</span>
            <Select
              className="buy-ctg"
              value={categoryId}
              onChange={(id) => {
                const find = prices.find((item) => item._id == id);
                if (find) {
                  // setCategory(find)
                  // setCategoryId(find._id)
                  setCategory(find);
                  setCategoryId(find._id);
                  const swap = prices.find((item) => item._id != find._id);
                  if (swap) {
                    setSwap(swap);
                    setSwapId(swap._id);
                  }
                }
              }}
            >
              {prices.map((item) => (
                <Select.Option
                  value={item._id}
                  label={
                    language == "en" ? item.categories : item.ar_categories
                  }
                >
                  {language == "en" ? item.categories : item.ar_categories}
                </Select.Option>
              ))}

              {/* <Select.Option  value="silver">Silver</Select.Option> */}
            </Select>
            {/* <Button className="CurrencySelector__SelectorButton" type="button" onClick={() => showMetalSelector(true)}>
                            <span className="CurrencySelector_img">
                               
                            </span>
                            <p className="text-callout">{category && category.categories} </p>
                            <div className="w-5 h-5">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5 ml-1 text-label-tertiary">
                                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </div>

                        </Button> */}
          </div>
          <div className="change-value-text">
            <button type="button" className="value-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                className=" "
              >
                <path
                  fill-rule="evenodd"
                  d="M15.707 4.293a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0zm0 6a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L10 14.586l4.293-4.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </Form.Item>

      <Form.Item className="username-form" label="" name="swapId">
        <div className="currency-selec-group">
          <div>
            <InputNumber
              value={swapQuantity}
              min={0}
              className="ant-input ant-input-status-success  buy-sale"
              onChange={(value) => {
                setSwapQuantity(value);
              }}
            />
          </div>
          <div className="currency-main d-flex align-items-center justify-center ps-4  ml-auto">
            <span className="currency-teg">OZ</span>
            <Select
              className="buy-ctg"
              value={swapId}
              onChange={(id) => {
                const find = prices.find((item) => item._id == id);
                if (find) {
                  setSwap(find);
                  setSwapId(find._id);
                  const category = prices.find((item) => item._id != find._id);
                  if (category) {
                    setCategory(category);
                    setCategoryId(category._id);
                  }
                }
              }}
            >
              {prices.map((item) => (
                <Select.Option
                  value={item._id}
                  label={
                    language == "en" ? item.categories : item.ar_categories
                  }
                >
                  {language == "en" ? item.categories : item.ar_categories}
                </Select.Option>
              ))}

              {/* <Select.Option  value="silver">Silver</Select.Option> */}
            </Select>
            {/* <Button className="CurrencySelector__SelectorButton" type="button" onClick={() => setSwapSelector(true)}>
                            <span className="CurrencySelector_img">
                              
                            </span>
                            <p className="text-callout">{swap && swap.categories} </p>
                            <div className="w-5 h-5">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5 ml-1 text-label-tertiary">
                                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                </svg>
                            </div>

                        </Button> */}
          </div>
        </div>
      </Form.Item>

      <div className="mb-3">
        {quantity > 0 && (
          <Collapse
            accordion
            className="collapse-main"
            // activeKey={'2'}
          >
            {[{ key: "2" }].map((item) => (
              <Panel
                key={item.key}
                header={
                  <div className="collaps-custom-header">
                    {lang("You get")}{" "}
                    <span className="bold-font">
                      {swapQuantity} oz{" "}
                      {swap && language == "en"
                        ? swap?.categories
                        : swap?.ar_categories}
                    </span>{" "}
                    {lang("for")}{" "}
                    <span className="bold-font">
                      {quantity} oz{" "}
                      {category && language == "en"
                        ? category?.categories
                        : category?.ar_categories}
                    </span>
                  </div>
                }
              >
                <div className="collaps-body">
                  {/* <div className="update-text">
                                <span>
                                    Base cost
                                    {/* <span className="text-label-secondary">@ A$110,381.92</span> *
                                </span>
                                <p>${(amount + processingFee).toFixed(2)}</p>
                            </div> */}
                  {/* <div className="update-text">
                                    <span>
                                        Network fee
                                        <Tooltip title="This fee covers our costs associated with asset transfer, and may vary depending on a number of factors, such as network congestion and operational costs." className="tooltip-bar">
                                            <span><InfoCircleOutlined /></span>
                                        </Tooltip>
                                    </span>
                                    <p>${processingFee.toFixed(2)}</p>
                                </div> */}
                </div>
              </Panel>
            ))}
          </Collapse>
        )}
      </div>
      <TimerCount count={10} onComplete={() => null} type={"Swap"} />

      {/* <div className="chose-visa-type">
                <div>
                    <img src={Visa} />
                </div>
                <div className="card-detail">
                    <span>Visa Debit - 8066</span>
                    <img src={Righterr} />
                </div>
            </div> */}

      <div className="d-flex mt-4">
        <Form.Item>
          <Button
            loading={loading}
            className="visa-in-btn"
            type="button"
            htmlType="submit"
          >
            {lang("Swap")} {category && category.categories}{" "}
            <img src={Righterr} />
          </Button>
        </Form.Item>
        {/* <Button className="re-visa-in-btn" type="button">
                    Review order
                </Button> */}
      </div>
    </Form>
  );
};

const MetalDropDown = ({ hide, onSelect, value, id }) => {
  const { prices } = useAppContext();
  const [metals, setMetals] = useState([
    ...prices.filter((item) => item._id != id),
  ]);

  const onSearch = ({ target }) => {
    console.log(target.value, "text");
    const filter = prices.filter((cur) =>
      cur.categories
        .toLocaleLowerCase()
        .includes(target.value.toLocaleLowerCase())
    );
    setMetals(filter);
  };

  return (
    <div className="currency-inset ">
      <div className="inset-header">
        <h1 className="">{lang("Select a metal")}</h1>
        <button className="headerclosebutton" type="button" onClick={hide}>
          <svg
            fill="none"
            height="24"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
      <div className="mt-4">
        <div className="searchbar-menu">
          <div className="serch-iconbar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-5 h-5 text-label-tertiary"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              ></path>
            </svg>
          </div>
          <Input
            className="currency-serch"
            placeholder={lang("Search")}
            onChange={onSearch}
          />
        </div>
      </div>
      <div className="currency-select-body">
        <ul>
          {metals.map((item) => (
            <li key={item}>
              <Button
                className="currency-btn-body"
                type="button"
                onClick={() => {
                  onSelect(item);
                  hide();
                }}
              >
                <span className="currency-flag">
                  <img
                    className=""
                    width={25}
                    src={item.atomicSymbol == "XAU" ? Gold : Silver}
                    alt="aud"
                  />
                </span>
                <div className="currency-names">
                  <span className="">
                    <h6 className="">{item.categories}</h6>
                    <p className="">{item.atomicSymbol}</p>
                  </span>
                </div>
                <span className="right-btn-icon ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    data-testid="chevron-right-icon"
                    width="20"
                    height="20"
                    color="var(--label-tertiary)"
                    className="text-label-tertiary"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Swap;
