import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import Countdown, { calcTimeDelta, zeroPad } from "react-countdown";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { useAppContext } from "../../context/AppContext";
import { useAuthContext } from "../../context/AuthContext";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { useMemo } from "react";
import lang from "../../helper/langHelper";

const TIMER_MILLISECONDS_TIME = 300000;

const BuyConfirm = ({}) => {
  const [loading, setLoading] = useState(false);
  const [confirming, setConfirming] = useState(false);

  const {
    prices,
    goldRates,
    silverRates,
    goldPrice,
    silverPrice,
    serviceFee,
    setRefreshBalance,
  } = useAppContext();
  const { isLoggedIn } = useAuthContext();
  const navigate = useNavigate();
  const [isGold, setIsGold] = useState(false);
  const { request } = useRequest();
  const [data, setData] = useState();
  const { id, type } = useParams();
  const [resendTimer, setResendTimer] = useState(60);

  const getResources = () => {
    setLoading(true);
    request({
      url: `/app/transaction/reviewOrder/${id}`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        console.log(data, "about-us");
        setLoading(false);
        if (data) {
          setData(data);
          data.categoryId && data.categoryId?.atomicSymbol == "XAU"
            ? setIsGold(true)
            : setIsGold(false);
        }
      },
      onError: (err) => {
        setLoading(false);
      },
    });
  };

  const transaction = () => {
    if (!data) return;

    const { _id: orderId, quantity, amount, payment_method } = data;

    const payload = {
      orderId,
      quantity,
      amount,
      payment_method,
    };

    setConfirming(true);
    request({
      url: `/app/transaction/transaction`,
      method: "POST",
      data: payload,
      onSuccess: (res) => {
        const { data } = res;
        console.log(data, "RESouce");
        setConfirming(false);
        ShowToast(`${type} successfully`, Severty.SUCCESS);
        setRefreshBalance((prev) => !prev);
        navigate("/");
      },
      onError: (err) => {
        setConfirming(false);
        if (err?.response?.data) {
          ShowToast(err?.response?.data?.message, Severty.ERROR);
        }
      },
    });
  };

  const timeKey = useMemo(() => {
    return Date.now() + TIMER_MILLISECONDS_TIME;
  }, []);

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [resendTimer]);

  useEffect(() => {
    getResources();
  }, []);

  return (
    <>
      <section className="main">
        <Header />
        <Container>
          <div className="home-outers">
            <div className="headding-fi">
              <h3>{type}</h3>
            </div>
            <Row gutter={24} className="align-items-center">
              <Col span={24} lg={12}>
                <div>
                  <h6>{lang("Confirm your transaction")}</h6>
                </div>
                <div className="left-outer-fi">
                  <div className="buying-header">
                    <h5>
                      {lang("You are")}{" "}
                      {type == lang("Buy")
                        ? lang("Buying")
                        : type == "Sell"
                        ? lang("Selling")
                        : lang("Swaping")}
                    </h5>
                    <h6> {data && data.categoryId.categories}</h6>
                    <p>
                      {lang("Spot at")}{" "}
                      {isGold
                        ? `$ ${goldPrice.toFixed(2)}`
                        : `$ ${silverPrice.toFixed(2)}`}
                    </p>
                  </div>
                  <div className="categ-fi">
                    <h6>{lang("Symbol")}</h6>
                    <h5>{isGold ? "CHG" : "CHS"}</h5>
                  </div>
                  <div className="categ-fi">
                    <h6>{lang("Qty")}</h6>
                    <h5>
                      {data && data.quantity.toFixed(3)} oz {lang("of")}{" "}
                      {isGold ? "CHG" : "CHS"} {lang("at")}{" "}
                      {isGold
                        ? `$ ${goldPrice.toFixed(2)}`
                        : `$ ${silverPrice.toFixed(2)}`}
                    </h5>
                  </div>

                  {type == "Buy" && (
                    <div className="categ-fi">
                      <h6>{lang("Payment Method")}</h6>
                      <div className="sub-categ-fi">
                        <div className="fi-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                            width="56"
                            height="56"
                          >
                            <g transform="translate(81.668 -382.533)">
                              <rect
                                width="63.214"
                                height="40.714"
                                x="-61.758"
                                y="413.183"
                                fill="#f9f9f9"
                                fill-rule="evenodd"
                                stroke="#000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width=".8"
                                rx="3.599"
                                ry="3.599"
                                class="colorf9f9f9 svgShape colorStroke000 svgStroke"
                              ></rect>
                              <path
                                fill="#005bac"
                                fill-rule="evenodd"
                                d="m211.328 184.445-23.465 144.208h37.542l23.468-144.208h-37.545zm-55.052 0-35.794 99.185-4.234-21.358.003.007-.933-4.787c-4.332-9.336-14.365-27.08-33.31-42.223a150.19 150.19 0 0 0-16.705-11.559l32.531 124.943h39.116l59.733-144.208h-40.407zm146.521 40.035c0-16.304 36.563-14.209 52.629-5.356l5.357-30.972s-16.534-6.288-33.768-6.288c-18.632 0-62.875 8.148-62.875 47.739 0 37.26 51.928 37.723 51.928 57.285 0 19.562-46.574 16.066-61.944 3.726l-5.586 32.373s16.763 8.148 42.382 8.148c25.616 0 64.272-13.271 64.272-49.37 0-37.493-52.395-40.985-52.395-57.285zm153.2-40.035h-30.185c-13.938 0-17.332 10.747-17.332 10.747l-55.988 133.461h39.131l7.828-21.419h47.728l4.403 21.419h34.472l-30.057-144.208zm-45.727 93.196 19.728-53.966 11.098 53.966H410.27z"
                                transform="translate(-58.272 406.232) scale(.10672)"
                                class="color005bac svgShape"
                              ></path>
                              <path
                                fill="#f6ac1d"
                                fill-rule="evenodd"
                                d="M104.132 198.022s-1.554-13.015-18.144-13.015H25.715l-.706 2.446s28.972 5.906 56.767 28.033c26.562 21.148 35.227 47.51 35.227 47.51l-12.871-64.974z"
                                transform="translate(-58.272 406.232) scale(.10672)"
                                class="colorf6ac1d svgShape"
                              ></path>
                            </g>
                          </svg>
                        </div>
                        {data?.payment_method == "cashBalance" && (
                          <div className="fi-text-name">
                            <h5>{data?.payment_method}</h5>
                            <p>{lang("Ending in")} 8066</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {type == "Sell" && (
                    <div className="categ-fi">
                      <h6>{lang("Deposite To")}</h6>
                      <div className="sub-categ-fi">
                        <div className="fi-icon">
                          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="56" height="56">
                                                        <g transform="translate(81.668 -382.533)">
                                                            <rect width="63.214" height="40.714" x="-61.758" y="413.183" fill="#f9f9f9" fill-rule="evenodd" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".8" rx="3.599" ry="3.599" class="colorf9f9f9 svgShape colorStroke000 svgStroke"></rect>
                                                            <path fill="#005bac" fill-rule="evenodd" d="m211.328 184.445-23.465 144.208h37.542l23.468-144.208h-37.545zm-55.052 0-35.794 99.185-4.234-21.358.003.007-.933-4.787c-4.332-9.336-14.365-27.08-33.31-42.223a150.19 150.19 0 0 0-16.705-11.559l32.531 124.943h39.116l59.733-144.208h-40.407zm146.521 40.035c0-16.304 36.563-14.209 52.629-5.356l5.357-30.972s-16.534-6.288-33.768-6.288c-18.632 0-62.875 8.148-62.875 47.739 0 37.26 51.928 37.723 51.928 57.285 0 19.562-46.574 16.066-61.944 3.726l-5.586 32.373s16.763 8.148 42.382 8.148c25.616 0 64.272-13.271 64.272-49.37 0-37.493-52.395-40.985-52.395-57.285zm153.2-40.035h-30.185c-13.938 0-17.332 10.747-17.332 10.747l-55.988 133.461h39.131l7.828-21.419h47.728l4.403 21.419h34.472l-30.057-144.208zm-45.727 93.196 19.728-53.966 11.098 53.966H410.27z" transform="translate(-58.272 406.232) scale(.10672)" class="color005bac svgShape"></path>
                                                            <path fill="#f6ac1d" fill-rule="evenodd" d="M104.132 198.022s-1.554-13.015-18.144-13.015H25.715l-.706 2.446s28.972 5.906 56.767 28.033c26.562 21.148 35.227 47.51 35.227 47.51l-12.871-64.974z" transform="translate(-58.272 406.232) scale(.10672)" class="colorf6ac1d svgShape">
                                                            </path>
                                                        </g>
                                                    </svg> */}
                          <svg
                            fill="#65C953"
                            viewBox="0 0 24 24"
                            id="wallet-5"
                            data-name="Flat Color"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon flat-color"
                          >
                            <path
                              id="primary"
                              d="M21,7H5A1,1,0,0,1,5,5H19a1,1,0,0,0,0-2H5A3,3,0,0,0,2,6H2V19a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V8A1,1,0,0,0,21,7Z"
                              style={{ fill: "#65C953" }}
                            ></path>
                            <circle
                              id="secondary"
                              cx="18"
                              cy="14"
                              r="1.5"
                              style={{ fill: "#fff" }}
                            ></circle>
                          </svg>
                        </div>
                        <div className="fi-text-name">
                          <h5>{lang("Cash Balance")}</h5>
                          <p> {lang("No Fee")}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <Form
                                        layout="vertical"
                                    >
                                        <Form.Item
                                            className="username-form"
                                            label="Coupon & Promotions"
                                        >
                                            <div className="fi-code-jen">
                                                <Input
                                                    placeholder="Enter Promo Code"
                                                    className="promo-code"
                                                    type="text"

                                                />
                                                <Button className="fi__SelectorButton" type="button" >
                                                    Apply Code
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    </Form> */}
                  <div className="fi-main-ft">
                    <div className="fi-footer">
                      <h6>{lang("Sub Total")}</h6>
                      <h6>$ {data && data.amount.toFixed(2)}</h6>
                    </div>
                    {/* {
                                            (type == 'Buy') &&
                                            <div className="fi-footer-last">
                                                <p>Network Fee</p>
                                                <p>${data && data.networkFees.toFixed(2)}</p>
                                            </div>

                                        } */}

                    {type == "Buy" && (
                      <div className="fi-footer-last">
                        <p>{lang("Processing Fee")}</p>
                        <p>$ {data && data.processingFees.toFixed(2)}</p>
                      </div>
                    )}

                    {type == "Sell" && (
                      <div className="fi-footer">
                        <h6>{lang("Total Payout")}</h6>
                        <h6>$ {data && data.total.toFixed(2)}</h6>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col span={24} lg={12}>
                <div className="right-buy-fi">
                  <div className="buying-header">
                    <h6>
                      {lang("Confirm your")} {type}
                    </h6>
                    <p>
                      {lang("Your pricing expires in")}
                      <Countdown
                        // ref={countRef}
                        key={0}
                        // date={Date.now() + TIMER_MILLISECONDS_TIME}
                        date={timeKey}
                        renderer={({ minutes, seconds }) => {
                          return (
                            <>
                              <div>
                                {minutes < 10 ? `0${minutes}` : minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                              </div>
                            </>
                          );
                        }}
                        onComplete={() => {
                          navigate(-1);
                        }}
                      />
                    </p>
                  </div>
                  {/* <p>By clicking "Confirm {type}", you are entering into a binding contract to purchase the stated quantity of metal at the stated price. OneGold will immediately charge your credit. card for the total shown and deposit the corresponding metal into your account.</p> */}
                  <div>
                    <Button className="btn-cancle" onClick={() => navigate(-1)}>
                      {lang("Cancel")}
                    </Button>
                    <Button
                      loading={confirming}
                      className="confirm-btn"
                      onClick={transaction}
                    >
                      {lang("Confirm")} {type}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default BuyConfirm;
