import {
    Button,
    Col,
    Modal,
    Row
} from "antd";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useEffect, useState } from "react";
import { Container, ModalBody, ModalTitle, Tab, Tabs } from "react-bootstrap";
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";

import OrderCard from "../../components/order/OrderCard";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import Gold from "./Gold";
import Silver from "./Silver";
import { useAppContext } from "../../context/AppContext";

function SilverPage() {
    const { request } = useRequest()
    const [searchText, setSearchText] = useState('');
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [customerGraphData, setGraphCustomerData] = useState({});
    const [visible, setVisible] = useState(false);
    const debouncedSearchText = useDebounce(searchText, 300);
    const [viewData, setViewData] = useState();
   const {silverPrice} =  useAppContext()

    const [productList, setProductList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [key, setKey] = useState('gold');


    const navigate = useNavigate()
    useEffect(() => {
        customerMonthlyCount({})
    }, [])


    const customerMonthlyCount = (data) => {
        const Cmonths = data?.userMonthlyGraph?.map((item) => item?.month);
        const Ccounts = data?.userMonthlyGraph?.map((item) => item?.count);

        const customerChartData = {
            series: [
                {
                    name: "User",
                    data: Ccounts,
                },
            ],
            options: {
                chart: {
                    height: 250,
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                },

                grid: {
                    row: {
                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5,
                    },
                },
                xaxis: {
                    categories: Cmonths,
                },
            },
        };
        setGraphCustomerData(customerChartData);
    };

    useEffect(() => {
        customerMonthlyCount({ userMonthlyGraph: [{ count: 14, month: "December 2023" }, { count: 20, month: "January 2024" }] })
    }, [])



    return (
        <>
            <section className="main">
                <Header />
                <Container>
                    <div className="home-outers">
                        <Row gutter={[16,0]}>
                            <Col span={24} lg={24} md={24}>

                                <Silver />

                            </Col>
                            <Col span={24} lg={8}>
                                {/* <OrderCard /> */}
                            </Col>
                        </Row>
                    </div>
                </Container>


            </section>
            <Footer />
        </>
    );
}



export default SilverPage;
