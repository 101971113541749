import {
  Col,
  Rate,
  Row
} from "antd";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";

import ReviewUser from '../../assets/images/banner.jpg';
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";

function ReviewIndex() {
  const { request } = useRequest()
  const [data, setData] = useState('');
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const getResources = () => {
    setLoading(true)
    request({
      url: `/app/content/get-content/terms-and-conditions`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        console.log(data, "terms-and-conditions");
        setLoading(false)
        if (data.data.length) {

          setData(data.data[0]);
        }
      },
      onError: (err) => {
        setLoading(false)
      }
    });
  };

  useEffect(() => {
    getResources()
  }, [])


  return (
    <>
      <section className="main">
        <Header />
        <Container>

          <div className="home-outers">
                <div className="tital transactions-tital ">
                  <h4 className="m-0">Review</h4>
                </div>
                <div className="review-main-page">
                  <div className="review-box">
                    <div className="review-icon">
                      <img src={ReviewUser} />
                    </div>
                    <div className="review-cont">
                      <div className="reivew-inner-cont">
                        <h5>Saaw</h5>
                        <Rate allowHalf defaultValue={4.5} />
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                      </div>
                      <div className="review-date">
                        <span>15/04/2024</span>
                      </div>
                    </div>
                  </div>
                  <div className="review-box">
                    <div className="review-icon">
                      <img src={ReviewUser} />
                    </div>
                    <div className="review-cont">
                      <div className="reivew-inner-cont">
                        <h5>Saaw</h5>
                        <Rate allowHalf defaultValue={4.5} />
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                      </div>
                      <div className="review-date">
                        <span>15/04/2024</span>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </Container>

      </section>
      <Footer />
    </>
  );
}



export default ReviewIndex;
